import { Dictionary } from '@reduxjs/toolkit'

const cardNumberMaskGroups = {
  13: [4, 3, 3, 3],
  14: [4, 6, 4],
  15: [4, 6, 5],
  16: [4, 4, 4, 4],
  17: [8, 9],
  18: [8, 10],
  19: [4, 4, 4, 7],
  20: [4, 4, 4, 4, 4],
}

const cardNumberMasks = Object.keys(cardNumberMaskGroups).reduce(
  (result: Dictionary<Array<RegExp | string>>, maskName: string) => {
    result[maskName] = cardNumberMaskGroups[maskName].reduce((maskArray: Array<RegExp | string>, count) => {
      if (maskArray.length) {
        maskArray.push(' ')
      }
      maskArray = maskArray.concat(new Array(count).fill(/\d/))

      return maskArray
    }, [])

    return result
  },
  {}
)

const LAST_MASK_KEY = Object.keys(cardNumberMasks).slice(-1).join()
const DEFAULT_MASK_KEY = '16'
const hiddenCardNumberMask = [/\*/, /\*/, /\*/, /\*/, ' ', /\d/, /\d/, /\d/, /\d/]
const VISIBLE_LAST_COUNT_SYMBOLS = 4
const MASKED_PREFIX = '**** '

export const getMaskByValue = (value: string, isBlured: boolean) => {
  const formattedValue = value.replace(/ /g, '')
  const isHidden = (value || '').slice(0, VISIBLE_LAST_COUNT_SYMBOLS) === MASKED_PREFIX.replace(/ /g, '')

  let minMaskIndex =
    Object.keys(cardNumberMasks).find((item: string) => Number(item) >= formattedValue.length) || LAST_MASK_KEY

  if (!isBlured) {
    minMaskIndex =
      Object.keys(cardNumberMasks).find(
        (item: string) => Number(item) >= formattedValue.length && item >= DEFAULT_MASK_KEY
      ) || LAST_MASK_KEY
  }

  return isHidden ? hiddenCardNumberMask : cardNumberMasks[minMaskIndex]
}
